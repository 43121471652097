import { get, post, del } from "./api.service";

const AssetService = {
  getAsset(params) {
    return get("user/asset", params);
  },
  downloadAsset(params) {
    return post("user/asset/download", params, true);
  },
  searchAsset(params) {
    return get("user/asset/search", params);
  },
  uploadAssetBegin(params) {
    return post("user/asset/upload/begin", params);
  },
  uploadAssetComplete(params) {
    return post("user/asset/upload/complete", params);
  },
  deleteAsset(assetId) {
    let params = { assetId: assetId };
    return del("user/asset", params, true);
  },
};

export { AssetService };
